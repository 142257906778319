<script setup lang="ts">
defineProps({
    colors: {
        type: Array as PropType<string[]>,
        default: () => ["#93A4BC", "#5A6273"]
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="32"
        height="28"
        viewBox="0 0 32 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.3884 3.8245C17.8444 3.4258 18.1333 2.83424 18.1333 2.17391C18.1333 0.973294 17.1782 0 16 0C14.8218 0 13.8667 0.973294 13.8667 2.17391C13.8667 2.83424 14.1556 3.4258 14.6116 3.8245L10.6757 8.50377C10.0514 9.24599 8.99782 9.43196 8.1649 8.94695L3.9859 6.51353C4.16455 6.19564 4.26667 5.82736 4.26667 5.43478C4.26667 4.23416 3.31154 3.26087 2.13333 3.26087C0.955126 3.26087 0 4.23416 0 5.43478C0 6.6354 1.06667 7.6087 2.48411 7.57945L4.16918 20.8822C4.22544 21.2262 4.51747 21.4783 4.85966 21.4783H27.1403C27.4825 21.4783 27.7746 21.2262 27.8308 20.8822L29.5159 7.57945C29.63 7.59869 29.7472 7.6087 29.8667 7.6087C31.0449 7.6087 32 6.6354 32 5.43478C32 4.23416 31.0449 3.26087 29.8667 3.26087C28.6885 3.26087 27.7333 4.23416 27.7333 5.43478C27.7333 5.82736 27.8355 6.19564 28.0141 6.51353L23.8351 8.94695C23.0022 9.43196 21.9486 9.24599 21.3243 8.50377L17.3884 3.8245Z"
            :fill="'url(#paint0_linear_308_521' + randomId + ')'"
        />
        <path
            d="M4.26667 25.4524C4.26667 25.0585 4.58007 24.7391 4.96667 24.7391H27.0333C27.4199 24.7391 27.7333 25.0585 27.7333 25.4524V27.2867C27.7333 27.6806 27.4199 28 27.0333 28H4.96667C4.58007 28 4.26667 27.6806 4.26667 27.2867V25.4524Z"
            :fill="'url(#paint1_linear_308_521' + randomId + ')'"
        />
        <defs>
            <linearGradient
                :id="'paint0_linear_308_521' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="28"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_308_521' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="28"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
        </defs>
    </svg>
</template>
